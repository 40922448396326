import "./scss/app.scss";

$(window).scroll(function () {
  let parOff1 = $(".par1").offset().top,
    parOff2 = $(".par2").offset().top,
    parOff3 = $(".par3").offset().top,
    parOff4 = $(".par4").offset().top,
    parOff5 = $(".par5").offset().top,
    foundOff = $(".found").offset().top,
    blackOff1 = $(".black1").offset().top,
    blackOff2 = $(".black2").offset().top,
    blackOff3 = $(".black3").offset().top,
    blackOff4 = $(".black4").offset().top,
    redParOff = $(".redPar").offset().top,
    windowHeight = $(window).height() / 1.3,
    windowScroll = $(window).scrollTop();

  if (windowScroll + windowHeight > parOff1) {
    $(".par1").addClass("selected");
  }
  if (windowScroll + windowHeight > parOff2) {
    $(".par2").addClass("selected");
  }
  if (windowScroll + windowHeight > parOff3) {
    $(".par3").addClass("selected");
  }
  if (windowScroll + windowHeight > parOff4) {
    $(".par4").addClass("selected");
  }
  if (windowScroll + windowHeight > parOff5) {
    $(".par5").addClass("selected");
  }

  if (windowScroll + windowHeight > foundOff) {
    $(".found").addClass("selected");
  }

  if (windowScroll + windowHeight > blackOff1) {
    $(".black1").addClass("selected");
  }
  if (windowScroll + windowHeight > blackOff2) {
    $(".black2").addClass("selected");
  }
  if (windowScroll + windowHeight > blackOff3) {
    $(".black3").addClass("selected");
  }
  if (windowScroll + windowHeight > blackOff4) {
    $(".black4").addClass("selected");
  }

  if (windowScroll + windowHeight > redParOff) {
    $(".redPar").addClass("selected");
  }

  $(".odd").css("left", windowScroll / 4);
  $(".even").css("left", -windowScroll / 4);
});
